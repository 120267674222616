import React from "react";
import right_banner from "../img/main-banner.png"

function PricePageHello({ onButtonClick }) {
  return (
    <div className="price-hello-page-root">
      <div className="price-hello-page-bg">
        <div className="price-hello-page-container">
          <div className="price-hello-page-left">
            <h1 className="price-hello-page-left-title">
              Принимаем любую технику по <span>самый высокой</span> оценке в
              Казахстане!
            </h1>
            <p className="price-hello-page-left-title2">
              Акция новым клиентам на первые 10 дней пользование🎉
            </p>
            <button onClick={onButtonClick} className="price-hello-page-left-button">Оцените технику за минуту 🔥</button>
          </div>
          <div className="price-hello-page-right">
            <img src={right_banner} alt="Main Banner"/>
          </div>
        </div>
      </div>

    </div>
  );
}

export default PricePageHello;
