import React, { useState } from "react";
import Navbar from "../fragments/Navbar";
import Footer from "../fragments/Footer";
import PricePageHello from "../fragments/PricePageHello";
import PricePageSteps from "../fragments/PricePageSteps";
import PricePageUpdated from "./PricePageUpdated";
import SocialLinks from "../fragments/SocialLinks";
import CallNumberInput from "../fragments/CallNumberInput";

function Home() {
  const [showBottomDiv, setShowBottomDiv] = useState(false);
  const [phoneCallPanelOn, setPhoneCallPanelOn] = useState(false);

  const handleButtonClick = () => {
    setShowBottomDiv(true);
    const element = document.getElementById("bottomFragment");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const togglePhonePanel = () => {
    setPhoneCallPanelOn((prevState) => !prevState); // Toggle phone panel state
  };

  return (
    <div>
      <Navbar togglePhonePanel={togglePhonePanel} />
      <PricePageHello onButtonClick={handleButtonClick} />
      <PricePageSteps />
      <PricePageUpdated showBottomDiv={showBottomDiv} />
      <Footer />
      <SocialLinks />
      <CallNumberInput isOn={phoneCallPanelOn} togglePhonePanel={togglePhonePanel} />
    </div>
  );
}

export default Home;
