import React, { useEffect, useState } from "react";
import Navbar from "../fragments/Navbar";
import Footer from "../fragments/Footer";
import unhiden from "../img/eye.png";
import hiden from "../img/hide.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { ref, get, set } from "firebase/database";
import { database } from "../firebase";
import axios from "axios";

const SMS_API_URL = 'https://script.google.com/macros/s/AKfycbx1_SzDgV8O2H8jWPjzvXHt5d03L8eXr2PRo_FvghJQc0SOeRF5vEKmQC39vTAZrNKd/exec';

function RestorePassword() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [smsCode, setSmsCode] = useState("");
  const [phoneNumberLast, setPhoneNumberLast] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHiden, setPasswordHiden] = useState(false);
  const [isSentSMS, setIsSentSMS] = useState(false);
  const [isSendingSMS, setIsSendingSMS] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedValue = localStorage.getItem('auth');
    if (storedValue) {
        navigate('/');
    }
  }, [navigate]);

  const handleSetSmsCode = (e) => {
    let value = e.target.value;
    let formattedValue = value.replace(/[^\d+()-]/g, "");
    setSmsCode(formattedValue);
  };

  const handleNumberInput = (e) => {
    let value = e.target.value;
    let formattedValue = value.replace(/[^\d+()-]/g, "");

    if (
      formattedValue.length === 4 ||
      formattedValue.length === 5 ||
      formattedValue.length === 6 ||
      formattedValue.length === 8 ||
      formattedValue.length === 9 ||
      formattedValue.length === 10 ||
      formattedValue.length === 12 ||
      formattedValue.length === 13 ||
      formattedValue.length === 15 ||
      formattedValue.length === 16
    ) {
      if (
        formattedValue[formattedValue.length - 1] === "(" ||
        formattedValue[formattedValue.length - 1] === ")" ||
        formattedValue[formattedValue.length - 1] === "-"
      ) {
        formattedValue = formattedValue.slice(0, -1);
      }
    }

    if (formattedValue.length === 1 && formattedValue[0] !== "+") {
      if (formattedValue === "8") {
        formattedValue = "+7(";
      } else {
        formattedValue = "+7(" + formattedValue;
      }
    }
    if (
      formattedValue.length === 2 &&
      phoneNumberLast.length > 2 &&
      phoneNumberLast[2] !== "("
    ) {
      formattedValue = formattedValue + "(";
    }

    if (formattedValue.length === 2 && formattedValue === "+7") {
      formattedValue = formattedValue + "(";
    }

    if (formattedValue.length === 6) {
      if (formattedValue + ")" !== phoneNumberLast) {
        formattedValue = formattedValue + ")";
      }
    } else if (formattedValue.length === 7 && formattedValue[6] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + ")" + formattedValue[6];
    }

    if (formattedValue.length === 10) {
      if (formattedValue + "-" !== phoneNumberLast) {
        formattedValue = formattedValue + "-";
      }
    } else if (formattedValue.length === 11 && formattedValue[10] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + "-" + formattedValue[10];
    }

    if (formattedValue.length === 13) {
      if (formattedValue + "-" !== phoneNumberLast) {
        formattedValue = formattedValue + "-";
      }
    } else if (formattedValue.length === 14 && formattedValue[13] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + "-" + formattedValue[13];
    }

    if (formattedValue.length > 16) {
      formattedValue = formattedValue.slice(0, 16 - formattedValue.length);
    }

    if (
      formattedValue.length === 4 ||
      formattedValue.length === 5 ||
      formattedValue.length === 6 ||
      formattedValue.length === 8 ||
      formattedValue.length === 9 ||
      formattedValue.length === 10 ||
      formattedValue.length === 12 ||
      formattedValue.length === 13 ||
      formattedValue.length === 15 ||
      formattedValue.length === 16
    ) {
      if (
        formattedValue[formattedValue.length - 1] === "(" ||
        formattedValue[formattedValue.length - 1] === ")" ||
        formattedValue[formattedValue.length - 1] === "-"
      ) {
        formattedValue = formattedValue.slice(0, -1);
      }
    }

    setPhoneNumberLast(formattedValue);

    setPhoneNumber(formattedValue);
  };

  const handleSetPassword = (e) => {
    let value = e.target.value;
    setPassword(value);
  };

  const togglePasswordVisibility = () => {
    setPasswordHiden((prevState) => !prevState);
  };

  const sendSMS = async () => {
    if(isSendingSMS){
      return
    }
    setIsSendingSMS(true);
    if (!phoneNumber || phoneNumber.length < 16) {
      toast.error("Введите корректный номер телефона", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const num = phoneNumber
      .replace("+7", "7")
      .replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replace("-", "")
      .toString();
    
    try {
      const response = await axios.get(SMS_API_URL, {
        params: {
          action: 'generate',
          number: num
        }
      });

      if (response.data === 'x5') {
        setIsSentSMS(true);
        setIsSendingSMS(false);
        toast.success("Сообщение отправлено", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setIsSendingSMS(false);
        toast.error("Ошибка при отправке сообщения", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setIsSendingSMS(false);
      console.error("Error sending SMS:", error);
      toast.error("Ошибка при отправке сообщения", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const RestorePassword = async () => {

    const num = phoneNumber
    .replace("+7", "7")
    .replace("(", "")
    .replace(")", "")
    .replace("-", "")
    .replace("-", "")
    .toString();
    const numF = phoneNumber
    .replace("+7", "8")
    .replace("(", "")
    .replace(")", "")
    .replace("-", "")
    .replace("-", "")
    .toString();

    try {
      const response = await axios.get(SMS_API_URL, {
        params: {
          action: 'check',
          number: num,
          code: smsCode,
        }
      });

      if (response.data === 'x6') {
        

  try {
    const userRef = ref(database, "User/" + numF + "id");
    const snapshot = await get(userRef);

    if (!snapshot.exists()) {
      toast.error("Пользователь с таким номером не найден", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const newUserRef = ref(database, "User/" + numF + "id");
    await set(newUserRef, {
      ...snapshot.val(),
      password: password,
    });

    toast.success("Пароль успешно восстановлен", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    navigate("/login");
  } catch (error) {
    console.error("Error restoring password:", error);
    toast.error("Ошибка при восстановлении пароля", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
      } else {
        toast.error("Неверный код подтверждения", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error sending SMS:", error);
      toast.error("Ошибка при проверке сообщения", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const registerButtonDisabled =
    !phoneNumber ||
    phoneNumber.length < 16 ||
    !password ||
    !smsCode ||
    password.length < 4 ||
    smsCode.length < 4;

  const buttonSendSMSIsOn =
    phoneNumber && phoneNumber.length > 15 && !isSentSMS;

  const buttonVerifyIsOn =
    phoneNumber && phoneNumber.length > 15 && isSentSMS;

  return (
    <div className="register-page-root">
      <Navbar />
      <div className="register-page-container">
        <h2 className="register-page-container-title">
        Восстановить пароль
        </h2>
        <div className="register-page-panel">
          
          

          <div className="register-page-number-input-container">
            <label htmlFor="number">Укажите номер телефона</label>
            <input
              autoComplete="off"
              type="tel"
              id="number"
              placeholder="+7 (___) ___-__-__"
              value={phoneNumber}
              onChange={handleNumberInput}
            />
          </div>

          {buttonSendSMSIsOn && (
            <div className="register-page-verify-number-container">
              <button onClick={sendSMS}>{isSendingSMS ? ("Отправка SMS...") :("Получить СМС-Код")}</button>
            </div>
          )}

          {buttonVerifyIsOn && (
            <div className="register-page-number-input-container">
              <input
                autoComplete="off"
                type="tel"
                id="number"
                placeholder="Введите код"
                value={smsCode}
                onChange={handleSetSmsCode}
              />
            </div>
          )}

          <div className="register-page-password-input-container">
            <label htmlFor="number">Придумайте пароль</label>
            <div className="register-page-password-input-root">
              <input
                autoComplete="off"
                type={passwordHiden ? "password" : "text"}
                id="password"
                placeholder=""
                value={password}
                onChange={handleSetPassword}
              />
              <div
                className="register-page-password-hiden-image"
                onClick={togglePasswordVisibility}
              >
                {passwordHiden ? (
                  <img src={hiden} alt="hiden password" />
                ) : (
                  <img src={unhiden} alt="hiden password" />
                )}
              </div>
            </div>
          </div>

          <button
            disabled={registerButtonDisabled}
            className="register-page-register-button"
            onClick={RestorePassword}
          >
            Восстановить
          </button>
        </div>
        <div className="register-page-link-to-login-panel">
          <Link to="/login">
            <button className="register-page-link-to-login-button">
              Войти
            </button>
          </Link>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default RestorePassword;
