import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../fragments/Navbar";
import Footer from "../fragments/Footer";
import Loading from "../fragments/Loading";
import { ref, get } from "firebase/database";
import { database } from "../firebase";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

const getListFromLocalStorage = (key) => {
  const list = localStorage.getItem(key);
  return list ? JSON.parse(list) : [];
};

const saveListToLocalStorage = (key, list) => {
  if (!Array.isArray(list)) {
    throw new Error("The list must be an array");
  }
  localStorage.setItem(key, JSON.stringify(list));
};

const addStringToList = (key, newString) => {
  let list = getListFromLocalStorage(key);
  list.push(newString);
  saveListToLocalStorage(key, list);
};

const isStringInList = (key, str) => {
  const list = getListFromLocalStorage(key);
  return list.includes(str);
};

const returnerPathCharacter = (str) => {
  if (str === "Смартфоны") {
    return "phone";
  } else if (str === "") {
    return "phone";
  } else {
    return "String is just right";
  }
};

function PostPage() {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [isBasket, setIsBasket] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsBasket(isStringInList("basketPosts", postId));
    const fetchPost = async () => {
      try {
        const postRef = ref(database, "Posts/" + postId);
        const snapshot = await get(postRef);
        if (snapshot.exists()) {
          const postData = snapshot.val();
          setPost(postData);

          const sumImage = postData.sumImage;
          const imageRefs = Array.from(
            { length: sumImage },
            (_, index) => `images/${postId}${index}.jpg`
          );
          const downloadedImages = await Promise.all(
            imageRefs.map(async (imageRef) => {
              try {
                const url = await storage.ref(imageRef).getDownloadURL();
                return url;
              } catch (error) {
                console.error("Error downloading image:", error);
                return null;
              }
            })
          );

          setImages(downloadedImages.filter((url) => url));

          const chRef = ref(
            database,
            "ProductCharacteristic/" + returnerPathCharacter(postData.category)
          );
          const chSnapshot = await get(chRef);
          if (chSnapshot.exists()) {
            const characterData = [];
            postData.data.forEach((character, index) => {
              const chT = character.character_type_id;
              characterData.push({
                id: chT,
                name: chSnapshot.child(chT).val().name,
                value: chSnapshot
                  .child(chT)
                  .child("list")
                  .child(character.character_id)
                  .val(),
              });
            });
            setCharacters(characterData);
          }
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();

  }, [postId]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  
  const handleClick = (url) => {
    window.open(url, '_blank');
  };
  

  const savePostToBasket = () => {
    if (!isStringInList("basketPosts", postId)) {
      addStringToList("basketPosts", postId);
      setIsBasket(isStringInList("basketPosts", postId));
    } else {
      navigate("/basket");
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!post) {
    return <div>Пост не найден</div>;
  }

  return (
    <div className="post-page-container">
      <Navbar />
      <div className="post-page-body">
        <div className="post-page-top">
          <div className="post-page-top-left-body">
            <p className="post-page-name-post">{post.name}</p>
            <p className="post-page-code-product">
              Код продукта: {post.codeProduct}, Адрес: {post.address}
            </p>
            <div className="post-page-top-left-container">
              <div className="post-page-image-container">
                <Slider {...settings}>
                  {images.map((imageUrl, index) => (
                    <div key={index}>
                      <img src={imageUrl} alt={`about ${index}`} />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="post-page-price-pody">
                <div className="post-page-price-pody-top">
                  <p className="post-page-price-pody-top-title">Цена</p>
                  <div className="post-page-price-pody-parent">
                    <div className="post-page-price-pody-parent-left">
                      <p className="post-page-price">{post.price}₸</p>
                      <p className="post-page-price-old">
                        {Math.round(post.price * 1.1)}₸
                      </p>
                    </div>
                  </div>
                  <p className="post-page-price-pody-credit-title">
                    Ежемесячная оплата
                  </p>
                  <p className="post-page-credit-info">
                    {Math.round((post.price * 1.18) / 12)} ₸ x 12 мес
                  </p>
                  <div className="post-page-buy-buttons-parent">
                    <button
                      className="post-page-buy-to-busket"
                      onClick={savePostToBasket}
                    >
                      {isBasket ? "Перейти в корзину" : "В корзину"}
                    </button>
                    <button onClick={() => handleClick('https://wa.me/77000919694?text=Здравствуйте, я хочу купить ' + post.name+ ', Код продукта: ' + post.codeProduct)} className="post-page-buy-now">Купить сейчас</button>
                  </div>
                </div>
                <div className="post-page-price-pody-bottom">
                  <p className="post-page-price-pody-bottom-title">Рассрочка</p>
                  <div className="post-page-price-pody-bottom-buttons-top">
                    <button className="post-page-price-pody-bottom-unselect">
                      Kaspi kz
                    </button>
                    <button className="post-page-price-pody-bottom-unselect">
                      Halyk Bank
                    </button>
                  </div>
                  <div className="post-page-price-pody-bottom-buttons-top">
                    <button className="post-page-price-pody-bottom-unselect">
                      Jusan
                    </button>
                    <button className="post-page-price-pody-bottom-unselect">
                      Freedom
                    </button>
                  </div>
                  <p className="post-page-price-pody-bottom-title">Срок</p>
                  <div className="post-page-price-pody-bottom-buttons-top">
                    <button className="post-page-price-pody-bottom-time-credit">
                      12 мес
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="post-page-top-right">
            <div className="post-page-top-right-top">
              <p className="post-page-top-right-top-title">Описание товара</p>
              <p className="post-page-description-text">{post.description}</p>
              <p className="post-page-description-text">
                Категория: {post.category}
              </p>
              <p className="post-page-description-text">Дата: {post.time}</p>
            </div>
            <div className="post-page-top-right-bottom">
              <p className="post-page-top-right-bottom-title">Характеристики</p>
              <ul>
                {characters.map((character, index) => (
                  <li key={index}>
                    <p>
                      {character.name}: {character.value}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PostPage;
