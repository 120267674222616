import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../fragments/Navbar";
import Footer from "../fragments/Footer";
import { ref, onValue, query, limitToFirst } from "firebase/database";
import { database } from "../firebase";
import PostItem from "../items/PostLargeItem";

const categoriesList = [
  "Смартфоны",
  "Ноутбуки",
  "Смарт-часы",
  "Наушники",
  "Бытовая техника",
  "Инструменты",
  "Игровые приставки",
  "Другие",
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Search() {
  const queryA = useQuery();
  const searchText = queryA.get("text");
  var categoryStart = queryA.get("startCategory");

  const [posts, setPosts] = useState([]);
  const [filterMin, setFilterMin] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [priceMin, setPriceMin] = useState("");
  const [priceMax, setPriceMax] = useState("");
  const [selectedCategories, setSelectedCategories] = useState({});

  useEffect(() => {
    if (categoryStart) {
      console.log(categoryStart);
      setSelectedCategories((prevState) => ({
        ...prevState,
        [categoryStart]: true,
      }));
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [categoryStart]);

  const handleInputChangePriceMin = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPriceMin(value);
  };

  const handleInputChangePriceMax = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setPriceMax(value);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const handleFilterMin = () => {
    setFilterMin(prevFilterMin => !prevFilterMin);
  };
  

  useEffect(() => {
    setIsLoading(true);
    const recentPostsRef = query(ref(database, "Posts"), limitToFirst(20));
    const fetchPosts = onValue(recentPostsRef, (snapshot) => {
      const data = snapshot.val();
      
      setIsLoading(false);
      const postsList = data
        ? Object.keys(data).map((key) => ({ id: key, ...data[key] }))
        : [];

      const filteredPosts = postsList.filter((post) => {
        const matchesSearchText =
          !searchText ||
          post.name.toLowerCase().includes(searchText.toLowerCase());
        const matchesPriceMin = !priceMin || post.price >= parseFloat(priceMin);
        const matchesPriceMax = !priceMax || post.price <= parseFloat(priceMax);
        const matchesCategory =
          Object.keys(selectedCategories).length === 0 ||
          Object.keys(selectedCategories).some(
            (cat) => selectedCategories[cat] && post.category === cat
          );

        return (
          matchesSearchText &&
          matchesPriceMin &&
          matchesPriceMax &&
          matchesCategory
        );
      });

      setPosts(filteredPosts);
    });

    return () => fetchPosts();
  }, [searchText, priceMin, priceMax, selectedCategories]);

  return (
    <div className="search-page-root">
      <Navbar />
      <div className="search-page-body">
        {!isMobile ? (
          <div className="search-page-body-left">
            <div className="search-page-filter-parent">
              <h3 className="search-page-filter-parent-price-title">Цена, ₸</h3>
              <div className="search-page-filter-price-min-parent">
                <p>От</p>
                <input
                  name="price_min"
                  inputMode="numeric"
                  type="text"
                  value={priceMin}
                  onChange={handleInputChangePriceMin}
                />
                <p>₸</p>
              </div>
              <div className="search-page-filter-price-max-parent">
                <p>До</p>
                <input
                  name="price_max"
                  inputMode="numeric"
                  type="text"
                  value={priceMax}
                  onChange={handleInputChangePriceMax}
                />
                <p>₸</p>
              </div>
              <h3>Категория</h3>
              {categoriesList.map((category) => (
                <div
                  className="search-page-filter-category-checkbox-parent"
                  key={category}
                >
                  <input
                    type="checkbox"
                    checked={!!selectedCategories[category]}
                    onChange={() => handleCategoryChange(category)}
                  />
                  <p>{category}</p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            {filterMin ? (
              <>
                <div>
                    <button className="search-filter-open-button" onClick={handleFilterMin}>
                        Фильтры
                    </button>
                </div>
              </>
            ) : (
              <>
                <div className="search-page-body-left">
                  <div className="search-page-filter-parent">
                    <h3 className="search-page-filter-parent-price-title">
                      Цена, ₸
                    </h3>
                    <div className="search-page-filter-price-min-parent">
                      <p>От</p>
                      <input
                        name="price_min"
                        inputMode="numeric"
                        type="text"
                        value={priceMin}
                        onChange={handleInputChangePriceMin}
                      />
                      <p>₸</p>
                    </div>
                    <div className="search-page-filter-price-max-parent">
                      <p>До</p>
                      <input
                        name="price_max"
                        inputMode="numeric"
                        type="text"
                        value={priceMax}
                        onChange={handleInputChangePriceMax}
                      />
                      <p>₸</p>
                    </div>
                    <h3>Категория</h3>
                    {categoriesList.map((category) => (
                      <div
                        className="search-page-filter-category-checkbox-parent"
                        key={category}
                      >
                        <input
                          type="checkbox"
                          checked={!!selectedCategories[category]}
                          onChange={() => handleCategoryChange(category)}
                        />
                        <p>{category}</p>
                      </div>
                    ))}
                    <button  className="search-filter-open-button" onClick={handleFilterMin}>
                    Скрыть фильтры
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <div className="search-page-body-right">
        {(posts.length < 1 && isLoading) && (
            <div className="search-page-loading-parent">

                <div className="loading"></div>
            </div>
        )}
          <div className="search-page-body-right-posts-parent">
            {posts.map((post) => (
              <PostItem key={post.id} post={post} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Search;
