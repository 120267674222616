import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>404 - Страница не найдена!</h1>
      <p>К сожалению, страница которую вы ищете, не существует.</p>
      <p>Вы всегда можете вернуться на  <Link style={{ color:'blue' }} to="/">главную страницу</Link>.</p>
    </div>
  );
}

export default NotFound;
