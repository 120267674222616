import React from "react";

function PricePageSteps() {
  return (
    <div className="price-step-root">
      <div className="price-step-bg">
        <div className="price-step-container">
          <div className="price-steps-item">
            <div className="price-steps-item-top">
                <span>1</span>
                <p>Оцените вашу технику за 2 минуты</p>
            </div>
            <div className="price-steps-item-bottom">
                <p>С помощью калькулятора на сайте или по телефону <span>+7 (700) 091-96-94</span></p>
            </div>
          </div>

          <div className="price-steps-item">
            <div className="price-steps-item-top">
                <span>2</span>
                <p>Подойдите в отделение с удостоверением личности</p>
            </div>
            <div className="price-steps-item-bottom">
                <p>Подойдет паспорт, удостоверение личности. Работаем с лицами старше 18 лет</p>
            </div>
          </div>


          <div className="price-steps-item">
            <div className="price-steps-item-top">
                <span>3</span>
                <p>Получите деньги через 5 минут</p>
            </div>
            <div className="price-steps-item-bottom">
                <p>Уже через 5 минут вы получите деньги на руки 🎉</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricePageSteps;
