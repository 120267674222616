import React from 'react';
import Navbar from '../fragments/Navbar';
import Footer from '../fragments/Footer';

function DocPrices() {
  return (
    <div>
      <Navbar/>
      <div className="doc-prices-root">
        <h3>Условия соглашения</h3>
        <div className="terms-content">
          <p>Добро пожаловать на наш веб-сайт!</p>
          <p>Эти условия и положения описывают правила и положения использования нашего веб-сайта.</p>
          <p>Используя этот веб-сайт, вы принимаете эти условия и положения в полном объеме. Если вы не согласны с этими условиями и положениями или любой частью этих условий и положений, вы не должны использовать этот веб-сайт.</p>

          <h4>Мы комиссионный магазин</h4>
          <p>Цена может быть снижена, если на продукте есть царапины или другие повреждения. Неоригинальные вещи не берем.</p>
          
          <h4>Лицензия на использование веб-сайта</h4>
          <p>Если не указано иное, мы и/или наши лицензиары владеем правами интеллектуальной собственности на веб-сайте и материалы на веб-сайте. В соответствии с лицензией ниже, все эти права интеллектуальной собственности защищены.</p>
          <p>Вы можете просматривать, загружать только для кэширования и печатать страницы, файлы или другой контент с веб-сайта для вашего личного использования, с учетом ограничений, указанных ниже и в других частях этих условий и положений.</p>

          <h4>Ограничения</h4>
          <p>Вам запрещается:</p>
          <ul>
            <li>Публиковать материалы с этого веб-сайта в других СМИ;</li>
            <li>Продавать, сдавать в аренду или предоставлять сублицензию на материалы с веб-сайта;</li>
            <li>Воспроизводить, дублировать, копировать или иным образом использовать материалы на этом веб-сайте в коммерческих целях;</li>
            <li>Редактировать или иным образом изменять любые материалы на веб-сайте;</li>
            <li>Распространять материалы с этого веб-сайта, за исключением случаев, когда содержание специально и явно разрешено для распространения.</li>
          </ul>

          <h4>Ответственность</h4>
          <p>Этот веб-сайт предоставляется "как есть" без каких-либо явных или подразумеваемых заявлений или гарантий. Мы не делаем никаких заявлений или гарантий в отношении этого веб-сайта или информации и материалов, предоставляемых на этом веб-сайте.</p>
          <p>Ничто в этом веб-сайте не является или не должно восприниматься как совет любого рода.</p>

          <h4>Изменения</h4>
          <p>Мы можем пересмотреть эти условия и положения время от времени. Пересмотренные условия и положения будут применяться к использованию этого веб-сайта с даты публикации пересмотренных условий и положений на этом веб-сайте. Пожалуйста, регулярно проверяйте эту страницу, чтобы убедиться, что вы знакомы с текущей версией.</p>

          <h4>Контакты</h4>
          <p>Если у вас есть вопросы по поводу этих условий и положений, пожалуйста, свяжитесь с нами.</p>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default DocPrices;
