import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();


function PostLargeItem({ post }) {
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getImageUrl = async () => {
      try {
        const imagePath = `products/${post.indexProduct}.jpg`;
        const imageRef = storage.ref(imagePath);
        const url = await imageRef.getDownloadURL();
        setImageUrl(url);
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    };

    getImageUrl();
  }, [post.indexProduct]);

  const handleClick = () => {
    navigate(`/post/${post.id}`);
  };
  
  return (

    <div className='post-large-item-container' onClick={handleClick}>

      {imageUrl && (
        <img src={imageUrl} alt={post.name} />
            )}

      <div className='post-large-item-text-parent'>
        <p className='post-large-item-name'>{post.name}</p>

        <div className='post-large-item-prices-parent'>
            
          <p className='post-large-item-price'>{post.price}₸</p>
          <p className='post-large-item-price-old'>{Math.round(post.price * 1.1)}₸</p>
        </div>
      </div>

    </div>
  );
}

export default PostLargeItem;
