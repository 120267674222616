import React from "react";
import whatsapp from "../img/whatsapp.png";
import telegram from "../img/telegram.svg";
import instagram from "../img/instagram.svg";

function SocialLinks() {
    const handleClick = (url) => {
        window.open(url, "_blank");
      };
    const instOpen = () => {
        handleClick("https://www.instagram.com/texno_finance.almaty");
    }
    const telegramOpen = () => {
        handleClick("https://t.me/texnofinance");
    }
    const whatsappOpen = () => {
        handleClick("https://wa.me/+77000797574")
    }
  return (
    <div className="social-link-root">
    

        <img onClick={whatsappOpen} src={whatsapp} alt="whatsapp link" />
        <img onClick={telegramOpen} src={telegram} alt="telegram link" />
        <img onClick={instOpen} src={instagram} alt="instagram link" />
    </div>
  );
}

export default SocialLinks;
