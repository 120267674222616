import React, { useEffect } from 'react';
import Navbar from '../fragments/Navbar'
import Footer from '../fragments/Footer'
import logo from "../img/logo.png"
import { ref, onValue, query, set, child } from "firebase/database";
import { database2 } from "../firebase";


class SendData {
    constructor(number, date, id) {
      this.number = number;
      this.date = date;
      this.id = id;
    }
  }
  
  const setStats = (number, date, id) => {
    const classRef = ref(database2, `AppDownload`);
  
    const newClassRef = child(classRef, id);
  
    const newClass = new SendData(number,  date, id);
  
    set(newClassRef, newClass)
      .then(() => {})
      .catch((error) => {
        console.error("Error sending values: ", error);
      });
  };

function RedirectDownloadMobileApp() {
    const google_play = "https://play.google.com/store/apps/details?id=com.beta.texnofinance";
    const app_store = "https://apps.apple.com/us/app/itexno/id6499319456";

    useEffect(() => {
        const isAndroid = /Android/i.test(navigator.userAgent);
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

        console.log("User Agent:", navigator.userAgent);
        console.log("Is Android:", isAndroid);
        console.log("Is iOS:", isIOS);

        const now = new Date();
        const day = String(now.getDate()).padStart(2, "0");
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const year = now.getFullYear();
        const hour = String(now.getHours() + 1).padStart(2, "0");
        const minute = String(now.getMinutes() + 1).padStart(2, "0");
        var timeId = hour + ":" + minute + "  " + day + "." + month + "." + year;
        var id = Date.now() + "id";
        var number_auth = " ";

        const storedValue = localStorage.getItem('auth');
        if (storedValue) {
            number_auth = storedValue;
        }
        setStats(number_auth, timeId, id);
        setTimeout(() => {
            
            if (isAndroid) {
                window.location.href = google_play;
            } else if (isIOS) {
                window.location.href = app_store;
            } else {
                window.location.href = google_play;
            }
        }, 2000);
    }, []);

    const download_link = () => {
        const isAndroid = /Android/i.test(navigator.userAgent);
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

        console.log("User Agent:", navigator.userAgent);
        console.log("Is Android:", isAndroid);
        console.log("Is iOS:", isIOS);

        if (isAndroid) {
            window.location.href = google_play;
        } else if (isIOS) {
            window.location.href = app_store;
        } else {
            window.location.href = google_play;
        }
    }

    return (
        <div className='redirect-app-download-root'>
        <Navbar/>
            <div className="redirect-app-download-background">
                <h1>Texno Finance</h1>
                <img src={logo} alt='Logo of Texno Finance'/>
                <button onClick={download_link}>Установить приложение</button>
            </div>
            <Footer/>
        </div>
    );
}

export default RedirectDownloadMobileApp;
