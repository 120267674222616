import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../img/logo.png";
import tenge from "../img/tenge.png";
import magnifying_glass from "../img/magnifying-glass.png";
import phone_ from "../img/phone.svg";
import location_ from "../img/location.svg";
//import basket_image from "../img/basket.png";

function Navbar({ togglePhonePanel }) {
  const [isMobile, setIsMobile] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const callCenterNumber = "+77000797574";

  const handleToggle = () => {
    togglePhonePanel();
  };
  
  

  useEffect(() => {
    const queryA = new URLSearchParams(location.search);
    setSearchText(queryA.get("text") || "");

    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location]);

  function handleSearch(event) {
    event.preventDefault();
    console.log("Searching...");
    navigate(`/search?text=${encodeURIComponent(searchText)}`);
  }

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img className="navbar-logo-image" src={logo} alt="Logo" />

          <div className="navbar-title-parent">
            <h1 className="navbar-title">Texno Finance</h1>
          </div>
          {!isMobile && (
            <div className="navbar-company-info">
              <p className="navbar-company-info-branch-sum">
                7 Oтделений в городе Алматы
              </p>
              <p className="navbar-company-info-time">24/7 Без выходных</p>
            </div>
          )}
        </Link>
        {!isMobile ? (
          <>
            <div className="navbar-search">
              {/*
              <form onSubmit={handleSearch}>
                <input
                  name="search"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Поиск"
                />
                <button type="submit">
                  <img
                    className="navbar-search-image"
                    src={magnifying_glass}
                    alt="Search"
                  />
                </button>
              </form>
            </div>
            <div className="navbar-links">
              <Link to="/search">
                <div className="link-with-image-text">
                  <img src={magnifying_glass} alt="search" />
                  <span>Искать</span>
                </div>
              </Link>
              <Link to="/basket">
                <div className="link-with-image-text">
                  <img src={basket_image} alt="Basket" />
                  <span>Корзина</span>
                </div>
              </Link> 
              */}

              <div
                className="link-open-phone-container"
              >
                <div className="link-with-image-text-h"
                  onClick={handleToggle}>
                  <img src={phone_} alt="Basket" />
                  <span>Позвонить</span>
                </div>
              </div>
              <a
                href="https://2gis.kz/almaty/branches/70000001080628399"
                className="link-open-phone-container"
              >
                <div className="link-with-image-text-h">
                  <img src={location_} alt="Basket" />
                  <span>Отделения</span>
                </div>
              </a>

              {location.pathname === "/admin" && (
                <Link to="/admin/price">
                  <div className="link-with-image-text">
                    <img src={tenge} alt="Basket" />
                    <span>Price</span>
                  </div>
                </Link>
              )}
            </div>
          </>
        ) : (
          <>
            <a
              href={`tel:${callCenterNumber}`}
              className="link-open-phone-container"
            >
              <div className="link-with-image-text-h">
                <img src={phone_} alt="Basket" />
                <span>Позвонить</span>
              </div>
            </a>
          </>
        )}
      </div>
      <>
        {location.pathname === "/search" && isMobile && (
          <>
            <div className="navbar-search">
              <form onSubmit={handleSearch}>
                <input
                  name="search"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Поиск"
                />
                <button type="submit">
                  <img
                    className="navbar-search-image"
                    src={magnifying_glass}
                    alt="Search"
                  />
                </button>
              </form>
            </div>
          </>
        )}
      </>
    </nav>
  );
}

export default Navbar;
