import React, { useState, useEffect } from "react";
import call_img from "../img/call.jpeg";
import close from "../img/close.png";
import { ref,  set, child } from "firebase/database";
import { database2 } from "../firebase";


class SendDataNumber {
    constructor(number, date, id) {
      this.number = number;
      this.date = date;
      this.id = id;
    }
  }
  
  const saveClassToFirebase = (number, date, id, day) => {
    const classRef = ref(database2, `FastCall`);
    const classRefToday = ref(database2, `Statistic/FastCall/${day}`);
  
    const newClassRef = child(classRef, id);
    const newStatisticRef = child(classRefToday, id);
  
    const newClass = new SendDataNumber(number, date, id);
  
    set(newClassRef, newClass)
      .then(() => {})
      .catch((error) => {
        console.error("Error sending values: ", error);
      });
      set(newStatisticRef, newClass)
      .then(() => {})
      .catch((error) => {
        console.error("Error sending values: ", error);
      });
  };
  

function CallNumberInput({ isOn, togglePhonePanel }) {
  const [isMobile, setIsMobile] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberLast, setPhoneNumberLast] = useState("");
  const[isSentBase, setIsSentBase] = useState(false);

  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOffPanel = () => {
    togglePhonePanel();
    setPhoneNumber("");
    setIsSentBase(false)
  };

  const handleButton = () => {
    setIsSentBase(true);
    const now = new Date();
    var id = Date.now() + "id";
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    const hour = String(now.getHours() + 1).padStart(2, "0");
    const minute = String(now.getMinutes() + 1).padStart(2, "0");
    var timeId = hour + ":" + minute + "  " + day + "." + month + "." + year;

    const dayId = day + "_" + month + "_" + year;
    saveClassToFirebase(phoneNumber,  timeId, id, dayId);

    setTimeout(handleOffPanel, 2000); 
  };


  const handleNumberInput = (e) => {
    let value = e.target.value;
    let formattedValue = value.replace(/[^\d+()-]/g, "");

    if (
      formattedValue.length === 4 ||
      formattedValue.length === 5 ||
      formattedValue.length === 6 ||
      formattedValue.length === 8 ||
      formattedValue.length === 9 ||
      formattedValue.length === 10 ||
      formattedValue.length === 12 ||
      formattedValue.length === 13 ||
      formattedValue.length === 15 ||
      formattedValue.length === 16
    ) {
      if (
        formattedValue[formattedValue.length - 1] === "(" ||
        formattedValue[formattedValue.length - 1] === ")" ||
        formattedValue[formattedValue.length - 1] === "-"
      ) {
        formattedValue = formattedValue.slice(0, -1);
      }
    }

    if (formattedValue.length === 1 && formattedValue[0] !== "+") {
      if (formattedValue === "8") {
        formattedValue = "+7(";
      } else {
        formattedValue = "+7(" + formattedValue;
      }
    }
    if (
      formattedValue.length === 2 &&
      phoneNumberLast.length > 2 &&
      phoneNumberLast[2] !== "("
    ) {
      formattedValue = formattedValue + "(";
    }

    if (formattedValue.length === 2 && formattedValue === "+7") {
      formattedValue = formattedValue + "(";
    }

    if (formattedValue.length === 6) {
      if (formattedValue + ")" !== phoneNumberLast) {
        formattedValue = formattedValue + ")";
      }
    } else if (formattedValue.length === 7 && formattedValue[6] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + ")" + formattedValue[6];
    }

    if (formattedValue.length === 10) {
      if (formattedValue + "-" !== phoneNumberLast) {
        formattedValue = formattedValue + "-";
      }
    } else if (formattedValue.length === 11 && formattedValue[10] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + "-" + formattedValue[10];
    }

    if (formattedValue.length === 13) {
      if (formattedValue + "-" !== phoneNumberLast) {
        formattedValue = formattedValue + "-";
      }
    } else if (formattedValue.length === 14 && formattedValue[13] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + "-" + formattedValue[13];
    }

    if (formattedValue.length > 16) {
      formattedValue = formattedValue.slice(0, 16 - formattedValue.length);
    }

    if (
        formattedValue.length === 4 ||
        formattedValue.length === 5 ||
        formattedValue.length === 6 ||
        formattedValue.length === 8 ||
        formattedValue.length === 9 ||
        formattedValue.length === 10 ||
        formattedValue.length === 12 ||
        formattedValue.length === 13 ||
        formattedValue.length === 15 ||
        formattedValue.length === 16
      ) {
        if (
          formattedValue[formattedValue.length - 1] === "(" ||
          formattedValue[formattedValue.length - 1] === ")" ||
          formattedValue[formattedValue.length - 1] === "-"
        ) {
          formattedValue = formattedValue.slice(0, -1);
        }
      }

    setPhoneNumberLast(formattedValue);

    setPhoneNumber(formattedValue);
  };

  const isNotActive = !phoneNumber || phoneNumber.length < 16

  return (
    <div>
      {isOn && !isMobile && (
        <div className="call-number-input-panel-root">
          <div className="call-number-input-panel">
            <div className="call-number-left">
              <img src={call_img} alt="Call" />
            </div>
            <div className="call-number-right">
              <div className="call-number-right-root">
                <div>
                  <p className="call-number-right-root-title">
                    Введите ваш номер телефона
                  </p>
                  <p className="call-number-right-root-info">
                    Специалист ломбарда позвонит вам в течение 10 минут
                  </p>
                </div>
                <input
                  type="text"
                  className="call-number-input"
                  placeholder="+7 (000) 000-00-00"
                  id="phone"
                  tabIndex={0}
                  autoComplete="off"
                  autoCorrect="off"
                  value={phoneNumber}
                  onChange={handleNumberInput}
                />

                <button onClick={handleButton} disabled={isNotActive}>{isSentBase ? "Вы на очереди" : "Позвоните мне"} </button>
              </div>
            </div>
          </div>

          <div className="close-image-root">
            <img onClick={handleOffPanel} src={close} alt="Close" />
          </div>
        </div>
      )}
    </div>
  );
}

export default CallNumberInput;
