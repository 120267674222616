import React, { useState, useEffect } from "react";
import Navbar from "../fragments/Navbar";
import Footer from "../fragments/Footer";
import unhiden from "../img/eye.png";
import hiden from "../img/hide.png";
import { Link, useNavigate } from "react-router-dom";
import { ref, get } from "firebase/database";
import { database } from "../firebase";

function Login() {
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberLast, setPhoneNumberLast] = useState();
  const [password, setPassword] = useState();
  const [passwordHiden, setPasswordHiden] = useState(true);
  const [loginError, setLoginError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const storedValue = localStorage.getItem('auth');
    if (storedValue) {
        navigate('/');
    }
  }, []);

  const handleNumberInput = (e) => {
    let value = e.target.value;
    let formattedValue = value.replace(/[^\d+()-]/g, "");

    if (
      formattedValue.length === 4 ||
      formattedValue.length === 5 ||
      formattedValue.length === 6 ||
      formattedValue.length === 8 ||
      formattedValue.length === 9 ||
      formattedValue.length === 10 ||
      formattedValue.length === 12 ||
      formattedValue.length === 13 ||
      formattedValue.length === 15 ||
      formattedValue.length === 16
    ) {
      if (
        formattedValue[formattedValue.length - 1] === "(" ||
        formattedValue[formattedValue.length - 1] === ")" ||
        formattedValue[formattedValue.length - 1] === "-"
      ) {
        formattedValue = formattedValue.slice(0, -1);
      }
    }

    if (formattedValue.length === 1 && formattedValue[0] !== "+") {
      if (formattedValue === "8") {
        formattedValue = "+7(";
      } else {
        formattedValue = "+7(" + formattedValue;
      }
    }
    if (
      formattedValue.length === 2 &&
      phoneNumberLast.length > 2 &&
      phoneNumberLast[2] !== "("
    ) {
      formattedValue = formattedValue + "(";
    }

    if (formattedValue.length === 2 && formattedValue === "+7") {
      formattedValue = formattedValue + "(";
    }

    if (formattedValue.length === 6) {
      if (formattedValue + ")" !== phoneNumberLast) {
        formattedValue = formattedValue + ")";
      }
    } else if (formattedValue.length === 7 && formattedValue[6] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + ")" + formattedValue[6];
    }

    if (formattedValue.length === 10) {
      if (formattedValue + "-" !== phoneNumberLast) {
        formattedValue = formattedValue + "-";
      }
    } else if (formattedValue.length === 11 && formattedValue[10] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + "-" + formattedValue[10];
    }

    if (formattedValue.length === 13) {
      if (formattedValue + "-" !== phoneNumberLast) {
        formattedValue = formattedValue + "-";
      }
    } else if (formattedValue.length === 14 && formattedValue[13] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + "-" + formattedValue[13];
    }

    if (formattedValue.length > 16) {
      formattedValue = formattedValue.slice(0, 16 - formattedValue.length);
    }

    if (
      formattedValue.length === 4 ||
      formattedValue.length === 5 ||
      formattedValue.length === 6 ||
      formattedValue.length === 8 ||
      formattedValue.length === 9 ||
      formattedValue.length === 10 ||
      formattedValue.length === 12 ||
      formattedValue.length === 13 ||
      formattedValue.length === 15 ||
      formattedValue.length === 16
    ) {
      if (
        formattedValue[formattedValue.length - 1] === "(" ||
        formattedValue[formattedValue.length - 1] === ")" ||
        formattedValue[formattedValue.length - 1] === "-"
      ) {
        formattedValue = formattedValue.slice(0, -1);
      }
    }

    setPhoneNumberLast(formattedValue);

    setPhoneNumber(formattedValue);
  };

  const handleSetPassword = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordHiden((prevState) => !prevState);
  };

  const handleLogin = async () => {
    if(phoneNumber.length < 16){
      return;
    }
    setLoginError(null);
    try {
      const userRef = ref(database, 'User/' + phoneNumber.replace("+7", "8").replace("(", "").replace(")", "").replace("-", "").replace("-", "") + 'id');
      
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        if (userData.password === password) {

          localStorage.setItem('auth', phoneNumber);
        navigate('/');

        } else {
          setLoginError('Неверный пароль');
        }
      } else {
        setLoginError('Пользователь не найден');
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoginError('Error logging in');
    }
  };

  const loginButtonDisabled = !phoneNumber || phoneNumber.length < 16 || !password;

  return (
    <div className="login-page-root">
      <Navbar />
      <div className="login-page-container">
        <h2 className="login-page-container-title">Вход на Texno Finance</h2>
        <div className="login-page-panel">
          <div className="login-page-number-input-container">
            <label htmlFor="number">Укажите номер телефона</label>
            <input
              autoComplete="off"
              type="text"
              id="phone_number"
              placeholder="+7 (___) ___-__-__"
              value={phoneNumber}
              onChange={handleNumberInput}
            />
          </div>

          <div className="login-page-password-input-container">
            <label htmlFor="number">Введите пароль</label>
            <div className="login-page-password-input-root">
              <input
                autoComplete="off"
                type={passwordHiden ? "password" : "text"}
                id="password"
                placeholder=""
                value={password}
                onChange={handleSetPassword}
              />
              <div
                className="login-page-password-hiden-image"
                onClick={togglePasswordVisibility}
              >
                {passwordHiden ? (
                  <img src={hiden} alt="hiden password" />
                ) : (
                  <img src={unhiden} alt="hiden password" />
                )}
              </div>
            </div>
          </div>

          <button disabled={loginButtonDisabled} onClick={handleLogin} className="login-page-login-button">Войти</button>
          {loginError && <p className="login-page-login-error">{loginError}</p>}

          <Link to="/restore"><p className="login-page-link-forgot-password">Я забыл пароль</p></Link>
        </div>
        <div className="login-page-link-to-register-panel">
          <Link to="/register">
            <button className="login-page-link-to-register-button">
              Зарегистрироваться
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
