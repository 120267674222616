import React, { useState, useEffect } from "react";
import BasketPostItem from "../items/BasketPostItem";
import Navbar from "../fragments/Navbar";
import Footer from "../fragments/Footer";
import { ref, get } from "firebase/database";
import { database } from "../firebase";

const getListFromLocalStorage = (key) => {
  const list = localStorage.getItem(key);
  return list ? JSON.parse(list) : [];
};

function Basket() {
  const [loadedPosts, setLoadedPosts] = useState([]);

  useEffect(() => {
    const savedPostIds = getListFromLocalStorage("basketPosts");

    const fetchPostsFromFirebase = async () => {
      const postsPromises = savedPostIds.map(async (postId) => {
        const postRef = ref(database, `Posts/${postId}`);
        const snapshot = await get(postRef);
        if (snapshot.exists()) {
          return { id: postId, ...snapshot.val() };
        } else {
          console.error(`Post with ID ${postId} does not exist in Firebase.`);
          return null;
        }
      });

      const fetchedPosts = await Promise.all(postsPromises);
      const filteredPosts = fetchedPosts.filter((post) => post !== null);
      setLoadedPosts(filteredPosts);
    };

    fetchPostsFromFirebase();
  }, []);

  const calculatePrices = () => {
    let totalPrice = 0;
    let discount = 0;
    var totalBuyText = '';

    loadedPosts.forEach((post) => {
      const itemPrice = post.price;
      totalPrice += itemPrice * 1.1;
      discount += itemPrice * 0.1;
      totalBuyText += (post.name + ', Код товара: ' + post.codeProduct + ' \n')
    });

    const finalTotal = totalPrice - discount;

    return {
      totalPrice: totalPrice.toFixed(1),
      discount: discount.toFixed(1),
      finalTotal: finalTotal.toFixed(1),
      totalBuyText: totalBuyText
    };
  };

  const { totalPrice, discount, finalTotal, totalBuyText } = calculatePrices();


  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="basket-page-root">
      <Navbar />
      <div className="basket-page-body">
        {loadedPosts.length === 0 ? (
          <h2>Корзина пуста</h2>
        ) : (
          <div className="basket-page-body-item-root">
            <div className="basket-page-body-posts-left">
              <h1>Корзина</h1>
              <div className="basket-page-body-posts-parent">
                {loadedPosts.map((post) => (
                  <BasketPostItem key={post.id} post={post} />
                ))}
              </div>
            </div>
            <div className="basket-page-body-posts-right">
              <div className="basket-page-body-right-text-line-1">
                <h4 className="basket-page-all-sum-price-info">
                  Цена без скидки
                </h4>
                <h4 className="basket-page-all-sum-price">{totalPrice}</h4>
              </div>
              <div className="basket-page-body-right-text-line-2">
                <h4 className="basket-page-all-sum-discount-info">Скидкa</h4>
                <h4 className="basket-page-all-sum-discount">{discount}</h4>
              </div>
              <div className="basket-page-body-right-text-line-3">
                <h4 className="basket-page-all-sum-total-info">Итого</h4>
                <h4 className="basket-page-all-sum-total">{finalTotal}</h4>
              </div>
              <div className="basket-page-contact-button-parent">
                <button className="basket-page-contact-button" onClick={() => handleClick('https://wa.me/77000919694?text=Здравствуйте, я хочу купить ' + totalBuyText)}>Cвязаться с продавцом</button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Basket;
