import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

const getListFromLocalStorage = (key) => {
  const list = localStorage.getItem(key);
  return list ? JSON.parse(list) : [];
};

const saveListToLocalStorage = (key, list) => {
  if (!Array.isArray(list)) {
    throw new Error('The list must be an array');
  }
  localStorage.setItem(key, JSON.stringify(list));
};

const addStringToList = (key, newString) => {
  let list = getListFromLocalStorage(key);
  list.push(newString);
  saveListToLocalStorage(key, list);
};

const isStringInList = (key, str) => {
  const list = getListFromLocalStorage(key);
  return list.includes(str);
};
const removeStringFromList = (key, str) => {
  let list = getListFromLocalStorage(key);
  list = list.filter(item => item !== str);
  saveListToLocalStorage(key, list);
};



function BasketPostItem({ post }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [isBasket, setIsBasket] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsBasket(isStringInList("basketPosts", post.id))
    const getImageUrl = async () => {
      try {
        const imagePath = `products/${post.indexProduct}.jpg`;
        const imageRef = storage.ref(imagePath);
        const url = await imageRef.getDownloadURL();
        setImageUrl(url);
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    };

    getImageUrl();
  }, [post.indexProduct]);

  const handleClick = () => {
    navigate(`/post/${post.id}`);
  };

  const removePostToBasket = () => {
    removeStringFromList("basketPosts", post.id)
    setIsBasket(false);
  };
  
  return (

    <div className={`basket-item-container ${!isBasket ? 'grayed-out' : ''}`} >

      {imageUrl && (
        <img src={imageUrl} alt={post.name} />
            )}

      <div className='basket-item-text-parent' onClick={handleClick}>
        <p className='basket-item-text-parent-name'>{post.name}</p>

        <div className='basket-item-prices-parent'>
          <p className='basket-item-price'>{post.price}₸</p>
          <p className='basket-item-price-old'>{Math.round(post.price * 1.1)}₸</p>
        </div>
        
      </div>

      <button className='basket-item-button' onClick={removePostToBasket}>
        {isBasket ? 'Удалить' : 'Удалено'}
        </button>

    </div>
  );
}

export default BasketPostItem;
