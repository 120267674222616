// RedirectProduct.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectProduct = () => {
  const location = useLocation();
  const [isNullPageLink, setIsNullPageLink] = useState(false);

  useEffect(() => {
    const getUrlParameter = (name) => {
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      const results = regex.exec(location.search);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };

    const urlParams = getUrlParameter('product');
    if(urlParams == null){
        setIsNullPageLink(true);
    }

    // Detect platform and set package accordingly
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isAndroid) {
      const url = `intent://texnofinance.kz/products/?product=${urlParams}#Intent;scheme=myapp;package=com.beta.texnofinance;end`;
      window.location.href = url;
    } else if (isIOS) {
      const url = `myapp://texnofinance.kz/products/?product=${urlParams}`;
      window.location.href = url;
    }else{
      const url = `intent://texnofinance.kz/products/?product=${urlParams}#Intent;scheme=myapp;package=com.beta.texnofinance;end`;
      window.location.href = url;
    }


  }, [location]);

  return (
    <div className='redirect-product-page-root'>
        {isNullPageLink ? (
        <>
            <h3 id="urlParamsValue">Продукт не найден</h3>
        </>) : (<>
            <h3 id="urlParamsValue">Перенаправление...</h3>
        </>
        )}
    </div>
  );
};

export default RedirectProduct;
