import React, { useState, useEffect, useRef } from "react";
import price_gadgets from "../img/price_gadgets.webp";
import devtools from 'devtools-detect';
import { Link, useNavigate } from "react-router-dom";
import { ref, onValue, query, set, child, onDisconnect } from "firebase/database";
import { database2 } from "../firebase";
import close from "../img/close.png";
import next_img from "../img/next.png"

class SendData {
  constructor(number, price, info, date, id) {
    this.number = number;
    this.price = price;
    this.info = info;
    this.date = date;
    this.id = id;
  }
}

const saveClassToFirebase = (number, price, info, date, id, day) => {
  if(number === "+7(777)777-77-77"){
    return;
  }
  const classRef = ref(database2, `Requests`);
  const classRefStatic = ref(database2, `Statistic/Requests/${day}`);

  const newClassRef = child(classRef, id);
  const newClassRefStatic = child(classRefStatic, id);

  const newClass = new SendData(number, price, info, date, id);
  const newClassStatic = new SendData(number, price, info, date, id);

  set(newClassRef, newClass)
    .then(() => {})
    .catch((error) => {
      console.error("Error sending values: ", error);
    });
    set(newClassRefStatic, newClassStatic)
    .then(() => {})
    .catch((error) => {
      console.error("Error sending values: ", error);
    });
};

const generateRandomUID = () => {

  const storedValue = localStorage.getItem('auth');
  if (storedValue) {
    return storedValue.replace("(","").replace(")","").replace("-","").replace("-","").replace("+7","8") + "id";
  }else{
    return 'uid-' + Math.random().toString(36).substr(2, 16);
  }
};

function PricePageUpdated({ showBottomDiv }) {
  const [prices, setPrices] = useState([]);
  const [last_sent_message, setLast_sent_message] = useState("");
  const [cpuList, setCpuList] = useState([]);
  const [filteredCpuList, setfilteredCpuList] = useState([]);
  const [gpuList, setGpuList] = useState([]);
  const [filteredGpuList, setfilteredGpuList] = useState([]);
  const [ramList, setRamList] = useState([]);
  const [filteredRamList, setfilteredRamList] = useState([]);
  const [storageList, setStorageList] = useState([]);
  const [filteredStorageList, setfilteredStorageList] = useState([]);
  const [searchCPU, setsearchCPU] = useState("");
  const [searchGPU, setsearchGPU] = useState("");
  const [searchRAM, setsearchRAM] = useState("");
  const [searchStorage, setsearchStorage] = useState("");
  const [selectedCPU, setselectedCPU] = useState("");
  const [selectedGPU, setselectedGPU] = useState("");
  const [selectedRAM, setselectedRAM] = useState("");
  const [selectedSTORAGE, setselectedSTORAGE] = useState("");
  const dropdownRefCPU = useRef(null);
  const dropdownRefGPU = useRef(null);
  const dropdownRefRAM = useRef(null);
  const dropdownRefSTORAGE = useRef(null);
  const dropdownSelectProduct = useRef(null);
  const [isOpenCPU, setIsOpenCPU] = useState(false);
  const [isOpenGPU, setIsOpenGPU] = useState(false);
  const [isOpenRAM, setIsOpenRAM] = useState(false);
  const [isOpenStorage, setIsOpenStorage] = useState(false);
  const [isOpenSelector, setIsOpenSelector] = useState(false);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const dropdownRefBattery = useRef(null);
  const [isOpenSelectorBattery, setIsOpenSelectorBattery] = useState(false);
  const [filteredBateryLevel, setFilteredBateryLevel] = useState([]);
  const [bateryLevelList] = useState([
    100, 99, 98, 97, 96, 95, 94, 93, 92, 91, 90, 89, 88, 87, 86, 85, 84, 83, 82,
    81, 80, 79, 78, 77, 76, 75, 74, 73, 72, 71, 70, 69, 68, 67, 66, 65, 64, 63,
    62, 61, 60, 59, 58, 57, 56, 55, 54, 53, 52, 51, 50, 49, 48, 47, 46, 45, 44,
    43, 42, 41, 40 /*, 39, 38, 37, 36, 35, 34, 33, 32, 31,
    30, 29, 28, 27, 26, 25, 24, 23, 22, 21,
    20, 19, 18, 17, 16, 15, 14, 13, 12, 11,
    10, 9, 8, 7, 6, 5, 4, 3, 2, 1*/,
  ]);

  const [isPC, setIsPC] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberLast, setPhoneNumberLast] = useState();
  const [batteryLevel, setBatteryLevel] = useState();
  const [deviceNew, setDeviceNew] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [beforeSelectedProduct, setBeforeSelectedProduct] = useState(" ");
  const [productValue, setProductValue] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const belowDivRefPC = useRef(null);
  const belowDivRefMobile = useRef(null);
  
  useEffect(() => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const handleDevToolsChange = () => {
      if (devtools.isOpen && !isIOS ) {
        window.location.href = 'about:blank'; 
      }
    };

    window.addEventListener('devtoolschange', handleDevToolsChange);

    const checkDevToolsOpen = () => {
      const threshold = 160;
      if (window.outerHeight - window.innerHeight > threshold || window.outerWidth - window.innerWidth > threshold) {
        handleDevToolsChange();
      }
    };

    const interval = setInterval(checkDevToolsOpen, 1000);

    return () => {
      window.removeEventListener('devtoolschange', handleDevToolsChange);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const uid = generateRandomUID();
    const userStatusDatabaseRef = ref(database2, `/Online/${uid}`);


    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const hour = String(now.getHours() + 1).padStart(2, "0");
    const minute = String(now.getMinutes() + 1).padStart(2, "0");
    var timeId = hour + ":" + minute + "  " + day + "." + month;

    const isOnlineForDatabase = {
      state: 'online',
      last_changed: timeId,
    };

    const connectedRef = ref(database2, '.info/connected');
    onValue(connectedRef, (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }

      onDisconnect(userStatusDatabaseRef).remove().then(() => {
        set(userStatusDatabaseRef, isOnlineForDatabase);
      });
    });
  }, []);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const storedValue = localStorage.getItem('auth');
    if (storedValue) {
      setPhoneNumber(storedValue);
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    if (deviceType) {
      const recentPostsRef = query(
        ref(database2, `PriceTech/Category/${deviceType}/l`)
      );
      const fetchPosts = onValue(recentPostsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const products = [];
          for (const key in data) {
            if (data.hasOwnProperty(key)) {
              const subData = data[key];

              for (const subKey in subData) {
                if (subData.hasOwnProperty(subKey)) {
                  const deeperSubData = subData[subKey];

                  for (const productKey in deeperSubData) {
                    if (deeperSubData.hasOwnProperty(productKey)) {
                      const product = deeperSubData[productKey];

                      if (product.a !== undefined && product.n_ !== undefined) {
                        products.push({
                          id: productKey,
                          name: subData.n_ + " " + product.n_ || "",
                          a: product.a,
                          b: product.b,
                          c: product.c,
                          d: product.d,
                          e: product.e,
                        });
                      }
                    }
                  }
                }
              }
            }
          }
          setPrices(products);
          setFilteredProductList(products.slice(0, 100));
          checkPcValues(products);
        }
      });

      return () => fetchPosts();
    }
  }, [deviceType]);

  const checkPcValues = (e) => {
    const gpuItems = e.filter((item) => item.name.includes("Видеокарта"));
    const cpuItems = e.filter((item) => item.name.includes("Процессор"));
    const ramItems = e.filter((item) => item.name.includes("ОЗУ"));
    const storageItems = e.filter((item) => item.name.includes("Память"));
    setGpuList(gpuItems);
    setCpuList(cpuItems);
    setRamList(ramItems);
    setStorageList(storageItems);
    setfilteredCpuList(cpuItems);
    setfilteredGpuList(gpuItems);
    setfilteredRamList(ramItems);
    setfilteredStorageList(storageItems);
  };

  const handleDeviceTypeChange = (e) => {
    setDeviceType(e.target.value);
    setFilteredProductList([]);
    setBatteryLevel("");
    setSearchTerm("");
    setSelectedProduct("");
    setProductValue(null);
    setPrices([]);
    setBeforeSelectedProduct(" ");

    if (e.target.value === "8pc") {
      setIsPC(true);
    } else {
      setIsPC(false);
    }
  };

  const handleProductSearch = (e) => {
    setSelectedProduct("");
    const searchTermc = e.target.value.toLowerCase();
    setSearchTerm(searchTermc);

    const filteredProducts = prices.filter((product) =>
      product.name.toLowerCase().includes(searchTerm)
    );

    setFilteredProductList(filteredProducts);
  };

  const handleNumberInput = (e) => {
    setProductValue(null);
    let value = e.target.value;
    let formattedValue = value.replace(/[^\d+()-]/g, "");

    if (
      formattedValue.length === 4 ||
      formattedValue.length === 5 ||
      formattedValue.length === 6 ||
      formattedValue.length === 8 ||
      formattedValue.length === 9 ||
      formattedValue.length === 10 ||
      formattedValue.length === 12 ||
      formattedValue.length === 13 ||
      formattedValue.length === 15 ||
      formattedValue.length === 16
    ) {
      if (
        formattedValue[formattedValue.length - 1] === "(" ||
        formattedValue[formattedValue.length - 1] === ")" ||
        formattedValue[formattedValue.length - 1] === "-"
      ) {
        formattedValue = formattedValue.slice(0, -1);
      }
    }

    if (formattedValue.length === 1 && formattedValue[0] !== "+") {
      if (formattedValue === "8") {
        formattedValue = "+7(";
      } else {
        formattedValue = "+7(" + formattedValue;
      }
    }
    if (
      formattedValue.length === 2 &&
      phoneNumberLast.length > 2 &&
      phoneNumberLast[2] !== "("
    ) {
      formattedValue = formattedValue + "(";
    }

    if (formattedValue.length === 2 && formattedValue === "+7") {
      formattedValue = formattedValue + "(";
    }

    if (formattedValue.length === 6) {
      if (formattedValue + ")" !== phoneNumberLast) {
        formattedValue = formattedValue + ")";
      }
    } else if (formattedValue.length === 7 && formattedValue[6] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + ")" + formattedValue[6];
    }

    if (formattedValue.length === 10) {
      if (formattedValue + "-" !== phoneNumberLast) {
        formattedValue = formattedValue + "-";
      }
    } else if (formattedValue.length === 11 && formattedValue[10] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + "-" + formattedValue[10];
    }

    if (formattedValue.length === 13) {
      if (formattedValue + "-" !== phoneNumberLast) {
        formattedValue = formattedValue + "-";
      }
    } else if (formattedValue.length === 14 && formattedValue[13] !== ")") {
      formattedValue = formattedValue.slice(0, -1) + "-" + formattedValue[13];
    }

    if (formattedValue.length > 16) {
      formattedValue = formattedValue.slice(0, 16 - formattedValue.length);
    }

    if (
      formattedValue.length === 4 ||
      formattedValue.length === 5 ||
      formattedValue.length === 6 ||
      formattedValue.length === 8 ||
      formattedValue.length === 9 ||
      formattedValue.length === 10 ||
      formattedValue.length === 12 ||
      formattedValue.length === 13 ||
      formattedValue.length === 15 ||
      formattedValue.length === 16
    ) {
      if (
        formattedValue[formattedValue.length - 1] === "(" ||
        formattedValue[formattedValue.length - 1] === ")" ||
        formattedValue[formattedValue.length - 1] === "-"
      ) {
        formattedValue = formattedValue.slice(0, -1);
      }
    }

    setPhoneNumberLast(formattedValue);

    setPhoneNumber(formattedValue);
  };

  const handleBatteryLevelInput = (e) => {
    setProductValue(null);
    const value = e.target.value;

    const formattedValue = value.replace(/\D/g, "");
    setBatteryLevel(formattedValue);

    setFilteredBateryLevel(bateryLevelList);

    if (formattedValue && formattedValue.length === 1) {
      const filtered = bateryLevelList.filter(
        (b) => b.toString().charAt(0) === formattedValue.toString()
      );
      setFilteredBateryLevel(filtered);
      setIsOpenSelectorBattery(true);
    } else if (formattedValue && formattedValue.length === 2) {
      const filtered = bateryLevelList.filter(
        (b) => b.toString() === formattedValue
      );
      setFilteredBateryLevel(filtered);
      setIsOpenSelectorBattery(true);
    }
  };

  const handleProductSelect = (product) => {
    setSearchTerm(product);
    setSelectedProduct(product);
    setFilteredProductList([]);
    setProductValue(null);
    setBeforeSelectedProduct(product);
  };

  const handleBatterySelect = (product) => {
    setBatteryLevel(product);
    setIsOpenSelectorBattery(false);
  };

  const handleCPUSearch = (e) => {
    setSelectedProduct("");
    const searchCPU = e.target.value.toLowerCase();
    setsearchCPU(searchCPU);
    setIsOpenCPU(true);
    setProductValue(null);

    const filtered = cpuList.filter((product) =>
      product.name.toLowerCase().includes(searchCPU)
    );

    setfilteredCpuList(filtered);
  };

  const handleGPUSearch = (e) => {
    setSelectedProduct("");
    const searchGPU = e.target.value.toLowerCase();
    setsearchGPU(searchGPU);
    setIsOpenGPU(true);
    setProductValue(null);

    const filtered = gpuList.filter((product) =>
      product.name.toLowerCase().includes(searchGPU)
    );

    setfilteredGpuList(filtered);
  };

  const handleRAMSearch = (e) => {
    setSelectedProduct("");
    const searchRAM = e.target.value.toLowerCase();
    setsearchRAM(searchRAM);
    setIsOpenRAM(true);
    setProductValue(null);

    const filtered = ramList.filter((product) =>
      product.name.toLowerCase().includes(searchRAM)
    );

    setfilteredRamList(filtered);
  };

  const handleStorageSearch = (e) => {
    setSelectedProduct("");
    const searchStorage = e.target.value.toLowerCase();
    setsearchStorage(searchStorage);
    setIsOpenStorage(true);
    setProductValue(null);

    const filtered = storageList.filter((product) =>
      product.name.toLowerCase().includes(searchStorage)
    );

    setfilteredStorageList(filtered);
  };

  const handleCPUSelect = (product) => {
    setProductValue(null);
    setsearchCPU(product);
    setselectedCPU(product);
    setfilteredCpuList([]);
    setIsOpenCPU(false);
  };
  const handleGPUSelect = (product) => {
    setProductValue(null);
    setsearchGPU(product);
    setselectedGPU(product);
    setfilteredGpuList([]);
    setIsOpenGPU(false);
  };
  const handleRAMSelect = (product) => {
    setProductValue(null);
    setsearchRAM(product);
    setselectedRAM(product);
    setfilteredRamList([]);
    setIsOpenRAM(false);
  };
  const handleStorageSelect = (product) => {
    setProductValue(null);
    setsearchStorage(product);
    setselectedSTORAGE(product);
    setfilteredStorageList([]);
    setIsOpenStorage(false);
  };

  const toggleDropdownCPU = () => {
    setIsOpenCPU(true);
  };
  const toggleDropdownGPU = () => {
    setIsOpenGPU(true);
  };
  const toggleDropdownRAM = () => {
    setIsOpenRAM(true);
  };
  const toggleDropdownStorage = () => {
    setIsOpenStorage(true);
  };
  const toggleDropdownBattery = () => {
    setIsOpenSelectorBattery(true);
    setFilteredBateryLevel(bateryLevelList);
    if (batteryLevel) {
      const value = batteryLevel;

      const formattedValue = value.toString().replace(/\D/g, "");
      setBatteryLevel(formattedValue);

      if (formattedValue && formattedValue.length === 1) {
        const filtered = bateryLevelList.filter(
          (b) => b.toString().charAt(0) === formattedValue
        );
        setFilteredBateryLevel(filtered);
      } else {
        setFilteredBateryLevel(bateryLevelList);
      }
    }
  };

  const toggleDropdownProductSelect = () => {
    setIsOpenSelector(true);
    if (beforeSelectedProduct.length > 1) {
      setSelectedProduct("");
      setSearchTerm("");
      setFilteredProductList(prices);
    }
  };

  const toggleSearchClearCPU = () => {
    setsearchCPU("");
    setselectedCPU("");
    setfilteredCpuList(cpuList);
  };
  const toggleSearchClearGPU = () => {
    setsearchGPU("");
    setselectedGPU("");
    setfilteredGpuList(gpuList);
  };
  const toggleSearchClearRAM = () => {
    setsearchRAM("");
    setselectedRAM("");
    setfilteredRamList(ramList);
  };
  const toggleSearchClearStorage = () => {
    setsearchStorage("");
    setselectedSTORAGE("");
    setfilteredStorageList(storageList);
  };

  const handleClickOutsidePC = (event) => {
    if (
      dropdownRefCPU.current &&
      !dropdownRefCPU.current.contains(event.target)
    ) {
      setIsOpenCPU(false);
    }
    if (
      dropdownRefGPU.current &&
      !dropdownRefGPU.current.contains(event.target)
    ) {
      setIsOpenGPU(false);
    }
    if (
      dropdownRefRAM.current &&
      !dropdownRefRAM.current.contains(event.target)
    ) {
      setIsOpenRAM(false);
    }
    if (
      dropdownRefSTORAGE.current &&
      !dropdownRefSTORAGE.current.contains(event.target)
    ) {
      setIsOpenStorage(false);
    }

    if (
      dropdownSelectProduct.current &&
      !dropdownSelectProduct.current.contains(event.target)
    ) {
      setIsOpenSelector(false);
    }
    if (
      dropdownRefBattery.current &&
      !dropdownRefBattery.current.contains(event.target)
    ) {
      setIsOpenSelectorBattery(false);
    }
  };

  useEffect(() => {
    if (!isOpenSelector) {
      if (beforeSelectedProduct.length > 1) {
        setSelectedProduct(beforeSelectedProduct);
        setSearchTerm(beforeSelectedProduct);
        setFilteredProductList(prices);
      }
    }
  }, [isOpenSelector]);

  const handleEvaluateClick = () => {
    const storedValue = parseInt(localStorage.getItem('col'));
    if (storedValue) {
      if(storedValue > 1){

        const storedValueAuth = localStorage.getItem('auth');

        if (!storedValueAuth) {
          
          navigate('/register');
          return;
        }else{
          
        }

      }else{
        var newToStore = storedValue + 1;

        localStorage.setItem('col', newToStore);
      }
    }else{
      localStorage.setItem('col', 1);
    }
    if (!isPC) {
      const selectedProductData = prices.find(
        (item) => item.name === selectedProduct
      );
      if (selectedProductData) {
        if (deviceNew) {
          setProductValue(formatNumberWithSpaces(selectedProductData.a));

          SaveUserInfo(selectedProductData, selectedProductData.a);

          scrollToResultPrice();
        } else {
          if (deviceType === "2phone_apple") {
            var inted = 0;
            if (selectedProductData.b === 0) {
              inted = selectedProductData.c;
            } else {
              inted = selectedProductData.b;
            }
            var procedMini = parseInt(inted / 100, 10);
            var procedMax = 0;

            if (
              selectedOptions.includes("Зарядное устройство") &&
              selectedOptions.includes("Коробка")
            ) {
              procedMax = parseInt(procedMini * (batteryLevel - 5), 10);

              procedMax = procedMax + parseInt(procedMini * 5, 10);
            } else {
              procedMax = parseInt(
                procedMini * (batteryLevel - (batteryLevel > 94 ? 10 : 12)),
                10
              );

              procedMax = procedMax + parseInt(procedMini * 5, 10);
            }

            setProductValue(formatNumberWithSpaces(procedMax));

            SaveUserInfo(selectedProductData, procedMax);

            scrollToResultPrice();
          } else {
            if (
              selectedOptions.includes("Зарядное устройство") &&
              selectedOptions.includes("Коробка")
            ) {
              setProductValue(formatNumberWithSpaces(selectedProductData.c));
              SaveUserInfo(selectedProductData, selectedProductData.c);
              scrollToResultPrice();
            } else {
              setProductValue(formatNumberWithSpaces(selectedProductData.d));
              SaveUserInfo(selectedProductData, selectedProductData.d);
              scrollToResultPrice();
            }
          }
        }
      }
    } else {
      const selectedProductCPU = cpuList.find(
        (item) => item.name === selectedCPU
      );
      const selectedProductRAM = ramList.find(
        (item) => item.name === selectedRAM
      );
      const selectedProductStorage = storageList.find(
        (item) => item.name === selectedSTORAGE
      );

      if (selectedProductCPU && selectedProductRAM && selectedProductStorage) {
        if (deviceNew) {
          var a =
            selectedProductCPU.a +
            selectedProductRAM.a +
            selectedProductStorage.a;

          if (selectedGPU) {
            const selectedProductGPU = gpuList.find(
              (item) => item.name === selectedGPU
            );
            a += selectedProductGPU.a;
          }
          setProductValue(formatNumberWithSpaces(a));
          scrollToResultPrice();
          SaveUserInfoPC(a);
        } else {
          var c = selectedProductRAM.c + selectedProductStorage.c;

          if (
            selectedOptions.includes("Зарядное устройство") &&
            selectedOptions.includes("Коробка")
          ) {
            c = c + selectedProductCPU.a;
          } else {
            c = c + selectedProductCPU.c;
          }
          if (selectedGPU) {
            const selectedProductGPU = gpuList.find(
              (item) => item.name === selectedGPU
            );
            c += selectedProductGPU.c;
          }
          setProductValue(formatNumberWithSpaces(c));
          scrollToResultPrice();
          SaveUserInfoPC(c);
        }
      } else {
      }
    }
  };

  const formatNumberWithSpaces = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const SaveUserInfo = (r, p) => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    const hour = String(now.getHours() + 1).padStart(2, "0");
    const minute = String(now.getMinutes() + 1).padStart(2, "0");
    var timeId = hour + ":" + minute + "  " + day + "." + month + "." + year;
    var id = Date.now() + "id";
    var info =
      r.name +
      ", [Комплект: " +
      (selectedOptions.length === 0 ? "Нет" : selectedOptions.join(", ")) +
      "] Емкость: " +
      (batteryLevel ? batteryLevel : "Нет") +
      ", Запечатанный: " +
      (deviceNew ? "Да" : "Нет");

      var day_id = day + "_"+month+"_"+year
    var toCheck = r.name + phoneNumber;
    if (last_sent_message !== toCheck) {
      setLast_sent_message(toCheck);
      saveClassToFirebase(phoneNumber, p, info, timeId, id, day_id);
    }
  };
  const SaveUserInfoPC = (r) => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    const hour = String(now.getHours() + 1).padStart(2, "0");
    const minute = String(now.getMinutes() + 1).padStart(2, "0");
    var timeId = hour + ":" + minute + "  " + day + "." + month + "." + year;
    var info =
      "PC - [ " +
      selectedCPU.replace("Процессор", "CPU:") +
      ", " +
      selectedGPU.replace("Видеокарта", "GPU:") +
      ", " +
      selectedRAM +
      ", " +
      selectedSTORAGE +
      ", [Комплект: " +
      (selectedOptions.length === 0 ? "Нет" : selectedOptions.join(", ")) +
      "], Запечатанный: " +
      (deviceNew ? "Да" : "Нет");

      var miniInfo = "PC - " +
      selectedCPU.replace("Процессор", "CPU:") +
      ", " +
      selectedGPU.replace("Видеокарта", "GPU:") +
      ", " +
      selectedRAM +
      ", " +
      selectedSTORAGE;
    var toCheck = miniInfo + phoneNumber;
    var day_id = day + "_"+month+"_"+year

    if (last_sent_message !== toCheck) {
      setLast_sent_message(toCheck);
      saveClassToFirebase(phoneNumber, r, info, timeId, Date.now() + "id", day_id);
    }
  };

  const scrollToResultPrice = () => {
    try {
      if (isMobile) {
        belowDivRefMobile.current.scrollIntoView({ behavior: "smooth" });
      } else {
        belowDivRefPC.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch {}
  };

  const handleCheckboxChange = (event) => {
    setProductValue(null);

    setSelectedOptions([]);
    setDeviceNew(event.target.checked);
    setBatteryLevel(100);
  };
  const handleCheckboxChangeAgree = (event) => {
    setProductValue(null);
    setConfirmed(event.target.checked);
  };

  const isEvaluateButtonDisabled =
    (isPC &&
      (!deviceType ||
        !confirmed ||
        !phoneNumber ||
        phoneNumber.length < 16 ||
        !selectedCPU ||
        !selectedRAM ||
        !selectedSTORAGE)) ||
    (!isPC &&
      (!deviceType ||
        !selectedProduct ||
        !confirmed ||
        !phoneNumber ||
        phoneNumber.length < 16 ||
        (deviceType === "2phone_apple" && !batteryLevel && !deviceNew)));

  //Dropdown Selector Комплектация таңдауға
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const options = ["Коробка", "Зарядное устройство", "Без комплекта"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleOption = (option) => {
    if (option === "Без комплекта") {
      if (selectedOptions.includes("Без комплекта")) {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      } else {
        setSelectedOptions([option]);
      }
    } else {
      if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      } else {
        if (!selectedOptions.includes("Без комплекта")) {
          setSelectedOptions([...selectedOptions, option]);
        } else {
          setSelectedOptions(
            selectedOptions.filter((item) => item !== "Без комплекта")
          );
          setSelectedOptions([option]);
        }
      }
    }
  };

  const removeOption = (option) => {
    setSelectedOptions(selectedOptions.filter((item) => item !== option));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleClickOutsidePC);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutsidePC);
    };
  }, []);

  return (
    <div className="price-page-root">
      <h2 id="bottomFragment" className="price-page-root-title">
        Воспользуйтесь нашим калькулятором для оценки
      </h2>
      <p className="price-page-root-info">
        За 1 минуту получите оценку вашей техники онлайн 🔥
      </p>

      <div className="price-page-container">
        <div className="price-page-device-selector">
          <h2>Под залог техники</h2>
          <div className="price-page-device-type-container">
            <select
              className="price-page-device-type-container-seleter"
              id="deviceType"
              name="deviceType"
              onChange={handleDeviceTypeChange}
              defaultValue=""
              value={deviceType}
            >
              <option value="" disabled>
                Укажите тип устройства
              </option>
              <option value="2phone_apple">Продукция Apple</option>
              <option value="1phone">Смартфон</option>
              <option value="3tablet">Планшет</option>
              <option value="4headphone">Наушники</option>
              <option value="5watch">Смарт-часы</option>
              <option value="6mac">Макбуки</option>
              <option value="7console">Play Station</option>
              <option value="8pc">Ноутбук / Компьютер</option>
              <option value="9camera">Камера</option>
            </select>
          </div>

          {deviceType && (
            <>
              {!isPC ? (
                <div
                  className="price-page-product-type-container"
                  ref={dropdownSelectProduct}
                >
                  <label className="" htmlFor="productType">
                    Марка смартфона
                  </label>
                  <div className="price-page-battery-input-root">
                    <input
                      className="price-page-search-possible-product"
                      type="text"
                      id="productType"
                      name="productType"
                      autoComplete="off"
                      autoCorrect="off"
                      placeholder={
                        beforeSelectedProduct.length > 1
                          ? beforeSelectedProduct
                          : "Искать марка смартфона"
                      }
                      value={searchTerm}
                      onChange={handleProductSearch}
                      onClick={toggleDropdownProductSelect}
                    />

                    <div className="price-page-battery-input-root-spanner">
                      <span>{isOpenSelector ? "▲" : "▼"}</span>
                    </div>
                  </div>

                  {isOpenSelector ? (
                    <>
                      {filteredProductList.length > 0 ? (
                        <ul className="product-dropdown">
                          {filteredProductList.map((product, index) => (
                            <li
                              className="price-page-search-possible-product-item"
                              key={index}
                              onClick={() => handleProductSelect(product.name)}
                            >
                              {product.name}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          {!selectedProduct && (
                            <>
                              {prices.length > 0 ? (
                                <p className="price-page-search-notFound">
                                  Не Найдено
                                </p>
                              ) : (
                                <div className="price-page-loading-parent">
                                  <div className="loading2"></div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {!selectedProduct && (
                        <>
                          {prices.length > 0 ? (
                            <></>
                          ) : (
                            <div className="price-page-loading-parent">
                              <div className="loading2"></div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div
                    className="price-page-product-type-container"
                    ref={dropdownRefCPU}
                  >
                    <label className="" htmlFor="productType">
                      Процессор
                    </label>
                    <div className="input-pc-character-container">
                      <input
                        className="price-page-search-possible-product"
                        type="text"
                        id="productType"
                        name="productType"
                        placeholder="Процессор"
                        value={searchCPU}
                        onChange={handleCPUSearch}
                        onClick={toggleDropdownCPU}
                      />

                      {searchCPU && (
                        <img
                          className="price-page-input-clear"
                          src={close}
                          alt="remove value cpu"
                          onClick={toggleSearchClearCPU}
                        />
                      )}
                    </div>

                    {isOpenCPU && (
                      <>
                        {selectedCPU && searchCPU === selectedCPU ? (
                          <>
                            <ul className="product-dropdown-mini">
                              {cpuList.map((product, index) => (
                                <li
                                  className="price-page-search-possible-product-item"
                                  key={index}
                                  onClick={() => handleCPUSelect(product.name)}
                                >
                                  {product.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <>
                            {filteredCpuList.length > 0 ? (
                              <ul className="product-dropdown-mini">
                                {filteredCpuList.map((product, index) => (
                                  <li
                                    className="price-page-search-possible-product-item"
                                    key={index}
                                    onClick={() =>
                                      handleCPUSelect(product.name)
                                    }
                                  >
                                    {product.name}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <>
                                {!selectedCPU && (
                                  <>
                                    {cpuList.length > 0 ? (
                                      <p className="price-page-search-notFound">
                                        Не Найдено
                                      </p>
                                    ) : (
                                      <div className="price-page-loading-parent">
                                        <div className="loading2"></div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className="price-page-product-type-container"
                    ref={dropdownRefGPU}
                  >
                    <label className="" htmlFor="productType">
                      Видеокарта
                    </label>

                    <div className="input-pc-character-container">
                      <input
                        className="price-page-search-possible-product"
                        type="text"
                        id="productType"
                        name="productType"
                        placeholder="Видеокарта"
                        value={searchGPU}
                        onChange={handleGPUSearch}
                        onClick={toggleDropdownGPU}
                      />

                      {searchGPU && (
                        <img
                          className="price-page-input-clear"
                          src={close}
                          alt="remove value gpu"
                          onClick={toggleSearchClearGPU}
                        />
                      )}
                    </div>

                    {isOpenGPU && (
                      <>
                        {selectedGPU && selectedGPU === searchGPU ? (
                          <>
                            <ul className="product-dropdown-mini">
                              {gpuList.map((product, index) => (
                                <li
                                  className="price-page-search-possible-product-item"
                                  key={index}
                                  onClick={() => handleGPUSelect(product.name)}
                                >
                                  {product.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <>
                            {filteredGpuList.length > 0 ? (
                              <ul className="product-dropdown-mini">
                                {filteredGpuList.map((product, index) => (
                                  <li
                                    className="price-page-search-possible-product-item"
                                    key={index}
                                    onClick={() =>
                                      handleGPUSelect(product.name)
                                    }
                                  >
                                    {product.name}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <>
                                {!selectedGPU && (
                                  <>
                                    {gpuList.length > 0 ? (
                                      <p className="price-page-search-notFound">
                                        Не Найдено
                                      </p>
                                    ) : (
                                      <div className="loading2"></div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className="price-page-product-type-container"
                    ref={dropdownRefSTORAGE}
                  >
                    <label className="" htmlFor="productType">
                      Память
                    </label>
                    <div className="input-pc-character-container">
                      <input
                        className="price-page-search-possible-product"
                        type="text"
                        id="productType"
                        name="productType"
                        placeholder="Память"
                        value={searchStorage}
                        onChange={handleStorageSearch}
                        onClick={toggleDropdownStorage}
                      />
                      {searchStorage && (
                        <img
                          className="price-page-input-clear"
                          src={close}
                          alt="remove value storage"
                          onClick={toggleSearchClearStorage}
                        />
                      )}
                    </div>
                    {}
                    {isOpenStorage && (
                      <>
                        {selectedSTORAGE &&
                        selectedSTORAGE === searchStorage ? (
                          <>
                            <ul className="product-dropdown-mini">
                              {storageList.map((product, index) => (
                                <li
                                  className="price-page-search-possible-product-item"
                                  key={index}
                                  onClick={() =>
                                    handleStorageSelect(product.name)
                                  }
                                >
                                  {product.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <>
                            {filteredStorageList.length > 0 ? (
                              <ul className="product-dropdown-mini">
                                {filteredStorageList.map((product, index) => (
                                  <li
                                    className="price-page-search-possible-product-item"
                                    key={index}
                                    onClick={() =>
                                      handleStorageSelect(product.name)
                                    }
                                  >
                                    {product.name}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <>
                                {!selectedSTORAGE && (
                                  <>
                                    {storageList.length > 0 ? (
                                      <p className="price-page-search-notFound">
                                        Не Найдено
                                      </p>
                                    ) : (
                                      <div className="loading2"></div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className="price-page-product-type-container"
                    ref={dropdownRefRAM}
                  >
                    <label className="" htmlFor="productType">
                      ОЗУ
                    </label>
                    <div className="input-pc-character-container">
                      <input
                        className="price-page-search-possible-product"
                        type="text"
                        id="productType"
                        name="productType"
                        placeholder="ОЗУ"
                        value={searchRAM}
                        onChange={handleRAMSearch}
                        onClick={toggleDropdownRAM}
                      />
                      {searchRAM && (
                        <img
                          className="price-page-input-clear"
                          src={close}
                          alt="remove value ram"
                          onClick={toggleSearchClearRAM}
                        />
                      )}
                    </div>

                    {isOpenRAM && (
                      <>
                        {selectedRAM && selectedRAM === searchRAM ? (
                          <>
                            <ul className="product-dropdown-mini">
                              {ramList.map((product, index) => (
                                <li
                                  className="price-page-search-possible-product-item"
                                  key={index}
                                  onClick={() => handleRAMSelect(product.name)}
                                >
                                  {product.name}
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <>
                            {filteredRamList.length > 0 ? (
                              <ul className="product-dropdown-mini">
                                {filteredRamList.map((product, index) => (
                                  <li
                                    className="price-page-search-possible-product-item"
                                    key={index}
                                    onClick={() =>
                                      handleRAMSelect(product.name)
                                    }
                                  >
                                    {product.name}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <>
                                {!selectedRAM && (
                                  <>
                                    {ramList.length > 0 ? (
                                      <p className="price-page-search-notFound">
                                        Не Найдено
                                      </p>
                                    ) : (
                                      <div className="loading2"></div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              <div className="price-page-device-new">
                <input
                  type="checkbox"
                  checked={deviceNew}
                  onChange={handleCheckboxChange}
                ></input>

                <span>Устройства запечатано, не активировано</span>
              </div>

              {deviceType === "2phone_apple" && !deviceNew && (
                <div
                  className="price-page-product-type-container"
                  ref={dropdownRefBattery}
                >
                  <label htmlFor="productType">Емкость аккумулятора</label>
                  <div className="price-page-battery-input-root">
                    <input
                      className="price-page-search-possible-product"
                      type="text"
                      id="battery_level"
                      placeholder="Емкость"
                      value={batteryLevel}
                      onChange={handleBatteryLevelInput}
                      onClick={toggleDropdownBattery}
                    />

                    <div className="price-page-battery-input-root-spanner">
                      <span>{isOpenSelectorBattery ? "▲" : "▼"}</span>
                    </div>
                  </div>

                  {isOpenSelectorBattery && (
                    <>
                      <ul className="product-dropdown-mini">
                        {filteredBateryLevel.map((product, index) => (
                          <li
                            className="price-page-search-possible-product-item"
                            key={index}
                            onClick={() => handleBatterySelect(product)}
                          >
                            {product}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              )}

              {!deviceNew && (
                <>
                  <div
                    className="price-page-multi-select-dropdown"
                    ref={dropdownRef}
                  >
                    <div
                      className="price-page-dropdown-header"
                      onClick={toggleDropdown}
                    >
                      {selectedOptions.length === 0 ? (
                        <span className="price-page-package-placeholder">
                          Комплектация
                        </span>
                      ) : (
                        selectedOptions.map((option) => (
                          <span
                            key={option}
                            className="price-page-package-selected-option"
                          >
                            {option}
                            <span
                              className="price-page-package-remove-option"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeOption(option);
                              }}
                            >
                              <img src={close} alt="remove-value" />
                            </span>
                          </span>
                        ))
                      )}
                      <span className="price-page-package-arrow">
                        {isOpen ? "▲" : "▼"}
                      </span>
                    </div>
                    {isOpen && (
                      <div className="price-page-package-dropdown-container">
                        <ul className="price-page-package-dropdown-list">
                          {options.map((option) => (
                            <li
                              key={option}
                              className={`price-page-package-dropdown-item ${
                                selectedOptions.includes(option)
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() => toggleOption(option)}
                            >
                              <span className="price-page-package-plus-icon">
                                +
                              </span>{" "}
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              )}

              {!isAuthenticated && ( 
              <div className="price-page-input-number-container">
                <label htmlFor="productType">Укажите номер телефона</label>
                <div className="price-page-input-number-root">
                  <input
                    autoComplete="off"
                    className="price-page-number-input"
                    type="text"
                    id="phone_number"
                    placeholder="+7 (___) ___-__-__"
                    value={phoneNumber}
                    onChange={handleNumberInput}
                  />
                  <Link to="/login">
                    
                  <div className="price-page-input-number-container-image" >
                    <img src={next_img} alt="hiden password" />
                  </div>

                  </Link>
                </div>
              </div>
              )}
            </>
          )}

          <button
            className="price-page-evaluate-button"
            onClick={handleEvaluateClick}
            disabled={isEvaluateButtonDisabled}
            
          >
            Оценить технику
          </button>

          <div ref={belowDivRefMobile} className="price-page-link-doc-parent">
            <input
              type="checkbox"
              checked={confirmed}
              onChange={handleCheckboxChangeAgree}
            ></input>

            <label className="price-page-link-doc">
              Я согласен(согласна) с
              <Link to="/document/prices"> условиями соглашения</Link>
            </label>
          </div>
        </div>

        {productValue && isMobile && (
          <>
            <div className="product-value">
              <div className="product-value-container">
                <div className="product-value-left">
                  <p>Устройствo:</p>
                  {deviceType !== "8pc" ? (
                    <h3>{selectedProduct}</h3>
                  ) : (
                    <h3>Ноутбук / Компьютер</h3>
                  )}
                  {selectedOptions.length > 0 ? (
                    <>
                      <p>Комплектация:</p>
                      <h3>{selectedOptions.join(", ")}</h3>
                    </>
                  ) : (
                    <>
                      {!deviceNew && (
                        <>
                          <p>Комплектация:</p>
                          <h3>Ничего</h3>
                        </>
                      )}
                    </>
                  )}
                  {deviceNew && (
                    <>
                      <p>Товар:</p>
                      <h3>Запечатан, не активирован</h3>
                    </>
                  )}

                  {deviceType === "2phone_apple" && !deviceNew && (
                    <>
                      <p>Емкость аккумулятора:</p>
                      <h3>{batteryLevel}</h3>
                    </>
                  )}
                </div>
                <div className="product-value-right">
                  <h1>{productValue} ₸</h1>
                  <p>
                    Получите эту сумму в любом отделении «Texno Finance» уже
                    сегодня
                  </p>
                  <a href="https://2gis.kz/almaty/branches/70000001080628399">
                    <button>Забрать деньги в отделении 💰</button>
                  </a>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="price-page-right">
          <div className="price-page-right-container">
            <img src={price_gadgets} alt="price page gadgets" />
            <p className="price-page-right-container-title">
              За технику вы получите сумму на руки на <span>15-20%</span> выше,
              чем у большинства ломбардов
            </p>
            <p className="price-page-right-container-info">
              Изделия, переданные в комиссионный магазин, находятся в
              безопасности и под охраной на весь срок хранение 🔒
            </p>
          </div>
        </div>
      </div>

      <div ref={belowDivRefPC}>
        {productValue && !isMobile && (
          <div className="product-value">
            <div className="product-value-container">
              <div className="product-value-left">
                <p>Устройствo:</p>
                {deviceType !== "8pc" ? (
                  <h2>{selectedProduct}</h2>
                ) : (
                  <h2>Ноутбук / Компьютер</h2>
                )}
                {selectedOptions.length > 0 ? (
                  <>
                    <p>Комплектация:</p>
                    <h2>{selectedOptions.join(", ")}</h2>
                  </>
                ) : (
                  <>
                    {!deviceNew && (
                      <>
                        <p>Комплектация:</p>
                        <h2>Ничего</h2>
                      </>
                    )}
                  </>
                )}
                {deviceNew && (
                  <>
                    <p>Товар:</p>
                    <h2>Запечатан, не активирован</h2>
                  </>
                )}

                {deviceType === "2phone_apple" && !deviceNew && (
                  <>
                    <p>Емкость аккумулятора:</p>
                    <h3>{batteryLevel}</h3>
                  </>
                )}
              </div>
              <div className="product-value-right">
                <h1>{productValue} ₸</h1>
                <p>
                  Получите эту сумму в любом отделении «Texno Finance» уже
                  сегодня
                </p>
                <a href="https://2gis.kz/almaty/branches/70000001080628399">
                  <button>Забрать деньги в отделении 💰</button>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PricePageUpdated;
